import React from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import { Icon } from "leaflet";
import IconUrl from "../../resources/MapMarker.svg";
import styled from "styled-components";

const StyledMapContainer = styled(MapContainer)`
  width: 100%;
  height: 480px;
  border-radius: 10px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
`;

const markerIcon = new Icon({
  iconUrl: IconUrl,
  iconSize: [50, 50],
});

//url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

function ContactMap() {
  return (
    <StyledMapContainer
      center={[-27.58846, -48.58016]}
      zoom={15}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://api.mapbox.com/styles/v1/charliebot/cky3forgk69ni14qiik59110i/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiY2hhcmxpZWJvdCIsImEiOiJja3kzZnIzZnMwMW1jMm9wOGtsbjNwYmwzIn0.0dMPwDOrBOrEEtZtHUhF3Q"
      />
      <Marker position={[-27.58546, -48.58229]} icon={markerIcon}>
        <Tooltip opacity={1} permanent direction="top" offset={[0, -15]}>
          Traceback Technologies
        </Tooltip>
      </Marker>
    </StyledMapContainer>
  );
}

export default ContactMap;
