import React from "react";
import { useMediaQuery } from "react-responsive";
import styled, { css } from "styled-components";

const ProductCardContainer = styled.div`
  width: 400px;
  height: 600px;
  background-color: #fff;
  box-shadow: 0px 0px 7px rgba(17, 17, 17, 0.2);
  border-radius: 5px;
  margin: 5px 0;

  @media screen and (max-width: 480px) {
    width: 340px;
    height: 620px;
  }

  ${({ isSamsung }) =>
    isSamsung
      ? css`
          @media screen and (max-width: 420px) {
            width: 300px;
            height: 680px;
          }
        `
      : css`
          @media screen and (max-width: 480px) {
            width: 340px;
            height: 620px;
          }
        `}
`;

const CardHeader = styled.div``;
const CardHeaderImg = styled.img`
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
`;
const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
`;

const CardTag = styled.span`
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: rgba(53, 53, 53, 0.88);
  margin: 5px 0;
`;

const DetailsText = styled.p`
  font-size: 16px;
  margin: 3px 0;

  @media screen and (max-width: 480px) {
    font-size: 15px;
  }
`;

function ProductCard(props) {
  const { imageUrl, details1, details2, details3, details4 } = props;

  const isSamsung = useMediaQuery({ query: "(width: 360px)" });

  return (
    <ProductCardContainer isSamsung={isSamsung}>
      <CardHeader>
        <CardHeaderImg src={imageUrl} />
      </CardHeader>
      <CardBody>
        <CardTag>Características Gerais</CardTag>
        <DetailsText>Protocolo Zigbee (IEEE 802.15.4)</DetailsText>
        <DetailsText>Frequência 2.4 Ghz – 16 Canais</DetailsText>
        <DetailsText>Potência máxima 16dBm (40 mW)</DetailsText>
        <DetailsText>
          Alcance 30m (interior de edifícios/típico) a 100m (ambientes amplos
          com visada direta)
        </DetailsText>
        <DetailsText>
          Dimensões: Largura 83 mm / comprimento 112 mm / altura 40 mm
        </DetailsText>
        <CardTag>Características Específicas</CardTag>
        <DetailsText>{details1}</DetailsText>
        <DetailsText>{details2}</DetailsText>
        <DetailsText>{details3}</DetailsText>
        <DetailsText>{details4}</DetailsText>
      </CardBody>
    </ProductCardContainer>
  );
}

export default ProductCard;
