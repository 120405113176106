import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
//import ContactImageSrc from "../../resources/Contact.svg";
import {
  faMapMarkerAlt,
  faPhoneVolume,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactMap from "../../components/map/ContactMap";

const ContactSectionContainer = styled(Element)`
  height: 760px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  background-color: #fff;
`;

const ContactInnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactTextIcon = styled.div`
  font-size: 30px;
  margin-right: 1em;
  color: #473e3b;
`;

const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0 2em;
  }
`;

const ContactTextContainer = styled.div`
  display: flex;
  margin: 5px 3px;
  align-items: center;
`;

const ContactTextWideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactText = styled.p`
  color: #7a7a7a;
  font-size: 18px;
  max-width: ${({ long }) => (long ? "100%" : "60%")};
  margin: 5px 0;

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

//const ContactImage = styled.img`
//  width: 24em;
//  height: 17em;
//`;

function ContactSection() {
  return (
    <ContactSectionContainer name="contact-section">
      <SectionTitle>Entre em contato conosco</SectionTitle>
      <ContactInnerContainer>
        <ContactInfoContainer>
          <ContactTextContainer>
            <ContactTextIcon>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </ContactTextIcon>
            <ContactTextWideContainer>
              <ContactText long>
                Rua General Liberato Bittencourt, 1885 - sala 508,
              </ContactText>
              <ContactText long>
                88070-800 Florianopolis Santa Catarina Brasil
              </ContactText>
            </ContactTextWideContainer>
          </ContactTextContainer>
          <Marginer direction="horizontal" margin="3em" />
          <ContactTextContainer>
            <ContactTextIcon>
              <FontAwesomeIcon icon={faPhoneVolume} />
            </ContactTextIcon>
            <ContactText long>+55 (48) 3879-7519</ContactText>
          </ContactTextContainer>
          <Marginer direction="horizontal" margin="3em" />
          <ContactTextContainer>
            <ContactTextIcon>
              <FontAwesomeIcon icon={faEnvelope} />
            </ContactTextIcon>
            <ContactText>info@traceback.com.br</ContactText>
          </ContactTextContainer>
        </ContactInfoContainer>
      </ContactInnerContainer>
      <Marginer direction="vertical" margin="2em" />
      <ContactMap />
      <Marginer direction="vertical" margin="2em" />
    </ContactSectionContainer>
  );
}

export default ContactSection;
