import React from "react";
import styled from "styled-components";
import { Marginer } from "../marginer";

const ServiceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isReversed }) => isReversed && "row-reverse"};
  margin-bottom: 2em;
  flex-wrap: wrap-reverse;

  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const ServiceImage = styled.img`
  width: 24em;
  height: 17em;

  @media screen and (max-width: 480px) {
    width: 20em;
    height: 15em;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;
  align-items: center;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    padding: 0.5em;
  }
`;

const Title = styled.h2`
  color: #000;
  font-size: 40px;
  font-weight: 500;
  margin: 5px 0;

  @media screen and (max-width: 480px) {
    margin-top: 1em;
    font-size: 26px;
  }
`;

const Details = styled.p`
  color: #7a7a7a;
  font-size: 20px;
  text-align: center;
  max-width: 60%;
  margin: 5px 0;

  @media screen and (max-width: 480px) {
    max-width: 100%;
    font-size: 16px;
  }
`;

const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SeeMoreText = styled.p`
  color: #65617d;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 220ms ease-in-out;

  &:hover {
    color: #9791bc;
  }
`;

function OurService(props) {
  const {
    title,
    description,
    imgUrl,
    description_extended,
    isReversed,
    seeMore,
  } = props;

  const [show, setShow] = React.useState(false);

  return (
    <ServiceContainer isReversed={isReversed}>
      <DescriptionContainer>
        <Title>{title}</Title>
        <Details>{description}</Details>
        {description_extended ? (
          <>
            {seeMore ? (
              show ? (
                <>
                  <SeeMoreText onClick={() => setShow(!show)}>
                    Ocultar
                  </SeeMoreText>
                  <Details>{description_extended}</Details>
                </>
              ) : (
                <SeeMoreContainer onClick={() => setShow(!show)}>
                  <SeeMoreText>Saiba mais...</SeeMoreText>
                </SeeMoreContainer>
              )
            ) : (
              <Details>{description_extended}</Details>
            )}
          </>
        ) : null}
      </DescriptionContainer>
      <Marginer direction="horizontal" margin="4em" />
      <ServiceImage src={imgUrl} />
    </ServiceContainer>
  );
}

export default OurService;
