import React from "react";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import ProductsSection from "./ProductsSection";
import HistorySection from "./HistorySection";
import Services from "./Services";
import TopSection from "./TopSection";
import ContactSection from "./ContactSection";
import Footer from "../../components/footer/Footer";
import { useMediaQuery } from "react-responsive";

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

function Homepage() {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <PageContainer>
      <TopSection />
      <Services />
      <HistorySection />
      <Marginer direction="vertical" margin="3em" />
      <ProductsSection />
      <Marginer direction="vertical" margin={isMobile ? "1em" : "3em"} />
      <ContactSection />
      <Marginer direction="vertical" margin="1em" />
      <Footer />
    </PageContainer>
  );
}

export default Homepage;
