import React from "react";
import { useMediaQuery } from "react-responsive";
import { scroller } from "react-scroll";
import styled from "styled-components";
import Button from "../button/Button";
import Logo from "../logo/Logo";
import { Marginer } from "../marginer";
import Dropdown from "../dropdown/Dropdown";

const NavBarContainer = styled.div`
  width: 100%;
  height: 65px;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: rgba(53, 53, 53, 0.7);
  z-index: 1600;

  @media screen and (max-width: 480px) {
    position: fixed;
  }
`;

const BrandContainer = styled.div``;
const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const SecondaryButton = styled(Button)`
  background-color: transparent;
  border: none;

  &:hover {
    background-color: transparent;
    border: none;
    color: rgb(180, 180, 180);
  }
`;

function Navbar() {
  const scrollToAboutSection = () => {
    scroller.scrollTo("history-section", { smooth: true, duration: 1300 });
  };

  const scrollToProductSection = () => {
    scroller.scrollTo("product-section", { smooth: true, duration: 1300 });
  };

  const scrollToTop = () => {
    scroller.scrollTo("top-section", { smooth: true, duration: 1300 });
  };

  const scrollToContact = () => {
    scroller.scrollTo("contact-section", { smooth: true, duration: 1300 });
  };

  const scrollToServices = () => {
    scroller.scrollTo("services-section", { smooth: true, duration: 1300 });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <NavBarContainer>
      <BrandContainer onClick={scrollToTop}>
        <Logo inline />
      </BrandContainer>
      {isMobile ? (
        <Dropdown />
      ) : (
        <NavigationContainer>
          <Button small={13} onClick={scrollToContact}>
            Contato
          </Button>
          <Marginer direction="horizontal" margin="8px" />
          <SecondaryButton small={13} onClick={scrollToAboutSection}>
            A Traceback
          </SecondaryButton>
          <Marginer direction="horizontal" margin="8px" />
          <SecondaryButton small={13} onClick={scrollToProductSection}>
            Produtos
          </SecondaryButton>
          <SecondaryButton small={13} onClick={scrollToServices}>
            Serviços
          </SecondaryButton>
        </NavigationContainer>
      )}
    </NavBarContainer>
  );
}

export default Navbar;
