import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.button`
  padding: ${({ small }) => (small ? "5px 8px" : "10px 16px")};
  border-radius: 5px;
  background-color: #ea7575;
  color: #fff;
  font-weight: ${({ small }) => (small ? 600 : 500)};
  font-size: ${({ small }) => (small ? small + "px" : "16px")};
  border: 2px solid transparent;
  outline: none;
  transition: all 220ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    border: 2px solid #ea7575;
  }
`;

function Button(props) {
  return <ButtonWrapper {...props}>{props.children}</ButtonWrapper>;
}

export default Button;
