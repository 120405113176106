import React from "react";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";
import HistoryUrl from "../../resources/history.svg";
import HistoryMobile from "../../resources/history_larger.svg";
import DownArrow from "../../components/downArrow/DownArrow";
import { useMediaQuery } from "react-responsive";

const HistorySectionContainer = styled(Element)`
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;

  @media screen and (max-width: 480px) {
    min-height: 300px;
    justify-content: center;
    padding: 5px;
  }
`;

const HistoryImage = styled.img`
  width: 70%;
  height: 80%;

  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
  }
`;

function HistorySection(params) {
  const scrollToNextSection = () => {
    scroller.scrollTo("product-section", { smooth: true, duration: 1500 });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <HistorySectionContainer name="history-section">
      {isMobile ? (
        <HistoryImage src={HistoryMobile} />
      ) : (
        <HistoryImage src={HistoryUrl} />
      )}
      <div onClick={scrollToNextSection}>
        <DownArrow />
      </div>
    </HistorySectionContainer>
  );
}

export default HistorySection;
