import React from "react";
import styled from "styled-components";
import Logo from "../logo/Logo";
import { Marginer } from "../marginer";
import { useMediaQuery } from "react-responsive";

const FooterContainer = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  background-color: #1f1f1f;
  align-items: center;
`;

const FooterBigText = styled.div`
  font-size: 28px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
  margin: 0;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    text-align: center;
    margin: 0 0.6em;
  }
`;

const AccessibilityContainer = styled.div`
  display: flex;
  width: 80%;
  border-top: 1px solid #cdcdcd;
  padding-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
  color: #fff;
`;

const FooterContactContainer = styled.div`
  display: flex;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const FooterContactText = styled.p`
  color: #fff;
  font-size: 16px;
  margin: 5px;
  max-width: ${({ long }) => (long ? "100%" : "60%")};
  margin-right: 3em;

  @media screen and (max-width: 480px) {
    font-size: 14px;
    max-width: 100%;
  }
`;

const RightsReserved = styled.div`
  position: relative;
  margin-top: 50px;
  color: #fff;
  font-size: 12px;
`;

function Footer(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <FooterContainer>
      <Marginer direction="vertical" margin="3em" />
      <Logo small />
      <Marginer direction="vertical" margin={isMobile ? "2em" : "5em"} />
      <FooterBigText>Experiência faz diferença</FooterBigText>
      <FooterBigText>
        Mais de 20 anos no desenvolvimento de tecnologia
      </FooterBigText>
      <Marginer direction="vertical" margin={isMobile ? "5em" : "8em"} />
      <AccessibilityContainer>
        <FooterContactContainer>
          <FooterContactText>
            Rua General Liberato Bittencourt, 1885 - sala 508, 88070-800
            Florianopolis Santa Catarina Brasil
          </FooterContactText>
          <FooterContactText long>+55 (48) 3879-7519</FooterContactText>
          <FooterContactText long>info@traceback.com.br</FooterContactText>
        </FooterContactContainer>
      </AccessibilityContainer>
      <RightsReserved>
        &copy; 2022 Traceback Todos os direitos reservados.
      </RightsReserved>
      {isMobile && <Marginer direction="vertical" margin={"1em"} />}
    </FooterContainer>
  );
}

export default Footer;
