import React from "react";
import { useMediaQuery } from "react-responsive";
import { scroller } from "react-scroll/modules";
import Element from "react-scroll/modules/components/Element";
import styled, { css } from "styled-components";
import Button from "../../components/button/Button";
import DownArrow from "../../components/downArrow/DownArrow";
import Logo from "../../components/logo/Logo";
import { Marginer } from "../../components/marginer";
import Navbar from "../../components/navbar/Navbar";
import BackgroundImg from "../../resources/router_2.jpg";

const TopContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0;
  background-image: url(${BackgroundImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.86);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CatchPhrase = styled.h1`
  margin: 0;
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  line-height: 1.4;
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
`;

const CatchPhraseSubtitle = styled.h2`
  margin: 0 0 4em 0;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  text-align: center;
  max-width: 60%;

  @media screen and (max-width: 480px) {
    font-size: 18px;
    max-width: 80%;
    margin: 0 0 3em 0;
  }
`;

const DownArrowContainer = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? css`
          position: relative;
          margin-top: 1em;
        `
      : css`
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
        `}
`;

function TopSection(props) {
  const scrollToNextSection = () => {
    scroller.scrollTo("services-section", { smooth: true, duration: 1500 });
  };

  const scrollToHistorySection = () => {
    scroller.scrollTo("history-section", { smooth: true, duration: 1500 });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  return (
    <Element name="top-section">
      <TopContainer>
        <BackgroundFilter>
          <Navbar />
          <Marginer direction="vertical" margin="8em" />
          <Logo />
          <Marginer direction="vertical" margin="6em" />
          <CatchPhrase>Experiência faz diferença</CatchPhrase>
          <Marginer direction="vertical" margin="1em" />
          <CatchPhraseSubtitle>
            A Traceback Technologies conta com mais de 20 anos no
            desenvolvimento de tecnologia electrônica e de software embarcdo
            para os mercados de Energia, Telecom, Saúde e Segurança.
          </CatchPhraseSubtitle>

          <Button onClick={scrollToHistorySection}>Saiba mais...</Button>
          <DownArrowContainer onClick={scrollToNextSection} isMobile={isMobile}>
            <DownArrow />
          </DownArrowContainer>
        </BackgroundFilter>
      </TopContainer>
    </Element>
  );
}

export default TopSection;
