import React from "react";
import styled, { css } from "styled-components";
import TracebackLogo from "../../resources/logo_fixed.svg";

const LogoContainer = styled.div`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? "row" : "column")};
  align-items: center;
`;

const LogoImg = styled.img`
  width: 7em;
  height: 7em;

  ${({ inline }) =>
    inline &&
    css`
      width: 28px;
      height: 28px;
      margin-right: 6px;
    `};

  ${({ small }) =>
    small &&
    css`
      width: 5em;
      height: 5em;
    `};
`;

const LogoText = styled.div`
  margin-top: ${({ inline }) => (inline ? 0 : "6px")};
  font-size: ${({ inline, small }) =>
    inline ? "18px" : small ? "36px" : "52px"};
  color: ${({ inline }) => (inline ? "#fff" : "#fff")};
  font-weight: ${({ inline }) => (inline ? "600" : "700")};

  ${({ inline }) =>
    inline &&
    css`
      cursor: pointer;
      &:hover {
        color: rgb(180, 180, 180);
      }
    `};
`;

const LogoTextBottom = styled.div`
  font-size: ${({ small }) => (small ? "24px" : "36px")};
  color: #ea7575;
  font-weight: 500;
`;

function Logo(props) {
  const { inline, small } = props;

  return (
    <LogoContainer inline={inline} small={small}>
      <LogoImg src={TracebackLogo} inline={inline} small={small} />
      <LogoText inline={inline} small={small}>
        Traceback
      </LogoText>
      {inline ? null : (
        <LogoTextBottom small={small}>Technologies</LogoTextBottom>
      )}
    </LogoContainer>
  );
}

export default Logo;
