import React from "react";
import styled from "styled-components";
import { Element, scroller } from "react-scroll";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import { Marginer } from "../../components/marginer";
import RouterImage from "../../resources/rth30rg.jpg";
import SensorImage from "../../resources/rth30t.jpg";
import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import ProductCard from "../../components/productCard/ProductCard";
import { useMediaQuery } from "react-responsive";

import "pure-react-carousel/dist/react-carousel.es.css";
import DownArrow from "../../components/downArrow/DownArrow";

const ProductSectionContainer = styled(Element)`
  min-height: 900px;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  background-color: #fafafa;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCarouselProvider = styled(CarouselProvider)`
  width: 50%;

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

const ProductSectionFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0;
  }
`;

const ProductsSectionFooterText = styled.p`
  font-size: 16px;
  font-weight: 400;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const ProductsSectionFooterEmailText = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: #e15f5f;
  margin: 0;
  margin-left: 3px;
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

function ProductsSection() {
  const scrollToNextSection = () => {
    scroller.scrollTo("contact-section", { smooth: true, duration: 1500 });
  };

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isMobileLarge = useMediaQuery({ query: "(min-width: 425px)" });
  const isMobileMedium = useMediaQuery({ query: "(min-width: 375px)" });
  const isMobileSmall = useMediaQuery({ query: "(min-width: 320px)" });

  return (
    <ProductSectionContainer name="product-section">
      <TitleContainer>
        <SectionTitle>
          Características de nossos Roteadores Digitais
        </SectionTitle>
      </TitleContainer>
      <Marginer direction="vertical" margin={isMobile ? "1em" : "3em"} />
      <StyledCarouselProvider
        naturalSlideWidth={250}
        naturalSlideHeight={
          isMobileLarge ? 400 : isMobileMedium ? 450 : isMobileSmall ? 540 : 340
        }
        totalSlides={2}
        visibleSlides={isMobile ? 1 : 2}
      >
        <Slider>
          <Slide index={0}>
            <ProductCard
              imageUrl={RouterImage}
              details1={
                "Interface 1-wire - sensores externos -30°C a +70°C com alimentação por pilhas alcalinas - 2 x AA.."
              }
              details2={
                "Sensor interno de temperatura ambiente e interface para RTD (Pt100) e Termopar (opcional)"
              }
            />
          </Slide>
          <Slide index={1}>
            <ProductCard
              imageUrl={SensorImage}
              details1={"Conexão e alimentação por interface USB 2.0."}
              details2={
                "Opera como Coordenador (Modelo G) ou Roteador (Modelo R)"
              }
            />
          </Slide>
        </Slider>
      </StyledCarouselProvider>
      <ProductSectionFooter>
        <ProductsSectionFooterText>
          Para compras, entrar em contato através do email
        </ProductsSectionFooterText>
        <ProductsSectionFooterEmailText>
          info@traceback.com.br.
        </ProductsSectionFooterEmailText>
      </ProductSectionFooter>
      {!isMobile && (
        <div onClick={scrollToNextSection}>
          <DownArrow />
        </div>
      )}
    </ProductSectionContainer>
  );
}

export default ProductsSection;
