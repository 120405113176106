import React from "react";
import styled, { css } from "styled-components";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UseDetectOutsideClick } from "./UseDetectOutsideClick";
import { scroller } from "react-scroll";

const ActiveMenu = styled.nav`
  background: #fafafa;
  border-radius: 15px;
  position: absolute;
  top: 60px;
  right: 5px;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ${({ isActive }) =>
    isActive
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        `
      : css``}
`;

const StyledUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li`
  border-bottom: 1px solid #dddddd;
`;

const StyledALink = styled.a`
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
`;

const StyledBarsIcon = styled.button`
  font-size: 24px;
  color: #fff;
  background-color: transparent;
  border: none;

  &:hover {
    color: #f2abab;
  }
  &::after {
    color: #ea7575;
  }
`;

function Dropdown() {
  const dropdownRef = React.useRef(null);
  const [isActive, setIsActive] = UseDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const scrollToAboutSection = () => {
    scroller.scrollTo("history-section", { smooth: true, duration: 1300 });
    setIsActive(!isActive);
  };

  const scrollToProductSection = () => {
    scroller.scrollTo("product-section", { smooth: true, duration: 1300 });
    setIsActive(!isActive);
  };

  const scrollToContact = () => {
    scroller.scrollTo("contact-section", { smooth: true, duration: 1300 });
    setIsActive(!isActive);
  };

  const scrollToServices = () => {
    scroller.scrollTo("services-section", { smooth: true, duration: 1300 });
    setIsActive(!isActive);
  };

  return (
    <div>
      <StyledBarsIcon>
        <FontAwesomeIcon icon={faBars} onClick={onClick} />
      </StyledBarsIcon>
      <ActiveMenu ref={dropdownRef} isActive={isActive}>
        <StyledUl>
          <StyledLi>
            <StyledALink onClick={scrollToContact}>Contato</StyledALink>
          </StyledLi>
          <StyledLi>
            <StyledALink onClick={scrollToAboutSection}>
              {" "}
              A Traceback
            </StyledALink>
          </StyledLi>
          <StyledLi>
            <StyledALink onClick={scrollToProductSection}>Produtos</StyledALink>
          </StyledLi>
          <StyledLi>
            <StyledALink onClick={scrollToServices}>Serviços</StyledALink>
          </StyledLi>
        </StyledUl>
      </ActiveMenu>
    </div>
  );
}

export default Dropdown;
