import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
import { Marginer } from "../../components/marginer";
import OurService from "../../components/ourService/OurService";
import { SectionTitle } from "../../components/sectionTitle/SectionTitle";
import EnergyImage from "../../resources/energy.svg";
import TelecomImage from "../../resources/Telecom.svg";
import SaudeImage from "../../resources/saude.svg";
import SegurancaImage from "../../resources/seguranca.svg";
import { text } from "../../data";
import DownArrow from "../../components/downArrow/DownArrow";
import { scroller } from "react-scroll/modules";

const ServicesContainer = styled(Element)`
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  background-color: #fafafa;
`;

function Services(props) {
  const scrollToNextSection = () => {
    scroller.scrollTo("history-section", { smooth: true, duration: 1500 });
  };

  return (
    <ServicesContainer name="services-section">
      <SectionTitle>Atuamos em várias áreas</SectionTitle>
      <Marginer direction="vertical" margin="3em" />
      <OurService
        title="Energia"
        description="Equipamentos e sistemas para automação e comunicação em processos de geração, transmissão e distribuição de energia."
        imgUrl={EnergyImage}
        description_extended="As soluções para a área de energia visam a automação de processos através de equipamentos e softwares que atendem a nichos específicos do setor, notadamente em produtos digitais de monitoramento, gerenciamento e controle."
      />
      <Marginer direction="vertical" margin="3em" />
      <OurService
        title="Telecom"
        description={text[0].description}
        imgUrl={TelecomImage}
        isReversed
        description_extended={text[0].description_extended}
        seeMore
      />
      <Marginer direction="vertical" margin="3em" />
      <OurService
        title="Saúde"
        description="Soluções para automação e comunicação em processos na área de saúde."
        imgUrl={SaudeImage}
        description_extended="As soluções para a área de saúde incluem desde equipamentos para monitoramento local e remoto de sinais em tempo real de temperatura, pressão, umidade, sinais vitais) até PDAs dedicados para cadastramento, coleta de dados, rastreamento e auditoria de informações médicas."
      />
      <Marginer direction="vertical" margin="3em" />
      <OurService
        title="Segurança"
        description="Sistemas e equipamentos para segurança pública e patrimonial."
        imgUrl={SegurancaImage}
        description_extended="Os sócios da Traceback desenvolveram projetos de grande porte para segurança pública. Um exemplo foi a automação do atendimento de emergência da Polícia Militar em parceria com a IBM. Também desenvolveram linhas de produtos para segurança patrimonial."
        isReversed
      />
      <div onClick={scrollToNextSection}>
        <DownArrow />
      </div>
    </ServicesContainer>
  );
}

export default Services;
